import React from 'react';

const StatusBadge = ({ status }) => {
  const getStatusClasses = (status) => {
    const baseClasses = "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium";
    
    switch (status.toLowerCase()) {
      case 'on track':
        return `${baseClasses} bg-green-100 text-green-800`;
      case 'at risk':
        return `${baseClasses} bg-yellow-100 text-yellow-800`;
      case 'delayed':
        return `${baseClasses} bg-red-100 text-red-800`;
      case 'completed':
        return `${baseClasses} bg-blue-100 text-blue-800`;
      case 'in progress':
        return `${baseClasses} bg-purple-100 text-purple-800`;
      case 'to do':
        return `${baseClasses} bg-gray-100 text-gray-800`;
      default:
        return `${baseClasses} bg-gray-100 text-gray-800`;
    }
  };

  return (
    <span className={getStatusClasses(status)}>
      {status}
    </span>
  );
};

export default StatusBadge; 