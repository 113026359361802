import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { CollapsibleSection } from './CollapsibleSection';

const KpiTrendsChart = ({ kpiRecords }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      month: 'short',
      year: 'numeric'
    });
  };

  const kpiGroups = kpiRecords?.reduce((groups, record) => {
    const kpiName = record.KPI_Name;
    if (!groups[kpiName]) {
      groups[kpiName] = [];
    }
    groups[kpiName].push({
      date: formatDate(record.Date),
      value: parseFloat(record.Actual),
      target: parseFloat(record.Target)
    });
    return groups;
  }, {}) || {};

  const kpiName = Object.keys(kpiGroups).find(name => name === 'NPS') || Object.keys(kpiGroups)[0];
  const kpiData = kpiGroups[kpiName] || [];
  
  // Sort data by date
  kpiData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white/95 border border-gray-200 rounded-lg p-3 shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">{label}</p>
          <p className="text-indigo-600 font-medium my-1">
            Value: {payload[0].value}
          </p>
          <p className="text-emerald-600 font-medium">
            Target: {payload[1].value}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <CollapsibleSection title={kpiName + "Trends"} defaultExpanded={true}>
      <div className="grid grid-cols-1gap-6">
    
        {kpiData.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={kpiData}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" className="opacity-50" />
              <XAxis 
                dataKey="date" 
                padding={{ left: 30, right: 30 }}
                tick={{ fill: '#4B5563', fontSize: 12 }}
              />
              <YAxis 
                tick={{ fill: '#4B5563', fontSize: 12 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line 
                type="monotone" 
                dataKey="value" 
                stroke="#6366F1" 
                name="Actual" 
                strokeWidth={2} 
                dot={{ r: 4 }} 
                activeDot={{ r: 6 }} 
              />
              <Line 
                type="monotone" 
                dataKey="target" 
                stroke="#10B981" 
                name="Target" 
                strokeWidth={2} 
                strokeDasharray="5 5" 
                dot={false} 
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-500 italic">No KPI data available</p>
          </div>
        )}
      </div>
    </CollapsibleSection>
  );
};

export default KpiTrendsChart; 