import React from 'react';
import CollapsibleSection from './CollapsibleSection';

const TeamMembersTable = ({ teamMembers, tasks }) => {
  if (!teamMembers || teamMembers.length === 0) return null;

  const getMemberStats = (memberId) => {
    const memberTasks = tasks?.filter(task => task.Assignee?.id === memberId) || [];
    const totalTasks = memberTasks.length;
    const completedTasks = memberTasks.filter(task => task.State === 'Done').length;
    const inProgressTasks = memberTasks.filter(task => task.State === 'In Progress').length;

    return {
      totalTasks,
      completedTasks,
      inProgressTasks,
      completion: totalTasks ? Math.round((completedTasks / totalTasks) * 100) : 0
    };
  };

  return (
    <CollapsibleSection title="Team Members">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Role
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Tasks
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  In Progress
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Completed
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Completion Rate
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {teamMembers.map((member) => {
                const stats = getMemberStats(member.id);
                return (
                  <tr key={member.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-blue-600">{member.Name}</div>
                      <div className="text-sm text-gray-500">{member.Email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-800">
                        {member.Role}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {stats.totalTasks}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {stats.inProgressTasks}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {stats.completedTasks}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-grow h-2 bg-gray-200 rounded-full overflow-hidden">
                          <div
                            className={`h-full rounded-full ${
                              stats.completion >= 70 ? 'bg-green-500' :
                              stats.completion >= 40 ? 'bg-yellow-500' :
                              'bg-red-500'
                            }`}
                            style={{ width: `${stats.completion}%` }}
                          />
                        </div>
                        <span className="ml-2 text-sm font-medium text-gray-700">
                          {stats.completion}%
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </CollapsibleSection>
  );
};

export default TeamMembersTable; 