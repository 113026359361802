import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import CollapsibleSection from './CollapsibleSection';


const ProjectsChart = ({ projects }) => {
  // Transform and filter only In Progress projects for the chart
  const chartData = projects?.filter(project => 
    project.State?.toLowerCase() === 'in progress'
  ).map(project => ({
    name: project.Project_Name,
    completion: project.Completion,
    status: project.State
  })) || [];

  // Custom tooltip content
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white/95 border border-gray-200 rounded-lg p-3 shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">{label}</p>
          <p className="text-emerald-600 font-medium my-1">
            Completion: {payload[0].value}%
          </p>
          <p className="text-gray-600 text-sm">
            Status: {payload[0].payload.status}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <CollapsibleSection title="Project Completion Status (In Progress)" defaultExpanded={true}>
      <div className="grid grid-cols-1gap-6">
      
        {chartData.length > 0 ? (
          <div className="w-full h-[400px]">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 100 }}
            >
              <CartesianGrid strokeDasharray="3 3" className="opacity-50" />
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                height={80}
                interval={0}
                tick={{ fill: '#4B5563', fontSize: 12 }}
              />
              <YAxis
                label={{ 
                  value: 'Completion %',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 10,
                  fill: '#4B5563',
                  fontSize: 12
                }}
                domain={[0, 100]}
                tick={{ fill: '#4B5563', fontSize: 12 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                dataKey="completion"
                fill="#10B981"
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-500 italic">
              No projects currently in progress
            </p>
          </div>
        )}
      </div>
      </CollapsibleSection>
  );
};

export default ProjectsChart; 