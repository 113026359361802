import React from 'react';

const ProgressBar = ({ progress }) => {
  const percentage = Math.min(Math.max(0, progress), 100); // Ensure progress is between 0 and 100
  
  // Determine color based on progress
  const getColor = (value) => {
    if (value < 30) return 'bg-red-500';
    if (value < 70) return 'bg-yellow-500';
    return 'bg-green-500';
  };

  return (
    <div className="flex items-center gap-2">
      <div className="flex-grow h-2.5 bg-gray-200 rounded-full overflow-hidden">
        <div
          className={`h-full rounded-full transition-all duration-300 ${getColor(percentage)}`}
          style={{ width: `${percentage}%` }}
        />
      </div>
      <span className="text-sm text-gray-600 font-medium min-w-[3rem]">
        {percentage}%
      </span>
    </div>
  );
};

export default ProgressBar; 