import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DashboardHeader from './DashboardHeader';
import DashboardStats from './DashboardStats';
import KpiCards from './KpiCards';
import StatusTables from './StatusTables';
import ProjectsChart from './ProjectsChart';
import TeamMembersTable from './TeamMembersTable';
import KpiTrendsChart from './KpiTrendsChart';
import GoalsTable from './GoalsTable';
import ErrorBoundary from './ErrorBoundary';

const Dashboard = ({ setIsAuthenticated, dashboardData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/login');
      return;
    }
    setIsLoading(false);
  }, [history]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    history.push('/login');
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-indigo-500 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <DashboardHeader 
        dashboardInfo={dashboardData?.dashboardInfo}
        onLogout={handleLogout}
      />
      <main className="container mx-auto px-4 py-8">
        <div className="space-y-8">
          <ErrorBoundary>
            <section>
              <DashboardStats stats={dashboardData?.stats} />
            </section>
          </ErrorBoundary>

          <ErrorBoundary>
            <section>
              <KpiCards kpiRecords={dashboardData?.kpiRecords} />
            </section>
          </ErrorBoundary>

          <ErrorBoundary>
            <section>
              <StatusTables 
                goals={dashboardData?.goals}
                projects={dashboardData?.projects}
                tasks={dashboardData?.tasks}
              />
            </section>
          </ErrorBoundary>

          <ErrorBoundary>
            <section>
              <ProjectsChart projects={dashboardData?.projects} />
            </section>
          </ErrorBoundary>

          <ErrorBoundary>
            <section>
              <TeamMembersTable 
                teamMembers={dashboardData?.teamMembers}
                tasks={dashboardData?.tasks}
              />
            </section>
          </ErrorBoundary>

          <ErrorBoundary>
            <section>
              <KpiTrendsChart kpiRecords={dashboardData?.kpiRecords} />
            </section>
          </ErrorBoundary>

          <ErrorBoundary>
            <section>
              <GoalsTable 
                goals={dashboardData?.goals}
                projects={dashboardData?.projects}
                tasks={dashboardData?.tasks}
              />
            </section>
          </ErrorBoundary>
        </div>
      </main>
    </div>
  );
};

export default Dashboard; 