import React from 'react';
import ProgressBar from './ProgressBar';

const ProjectModal = ({ project, isOpen, onClose }) => {
  if (!isOpen) return null;

  const StatusBadge = ({ status }) => (
    <span className={`
      inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
      ${status === 'Done' ? 'bg-green-100 text-green-800' :
        status === 'In Progress' ? 'bg-blue-100 text-blue-800' :
        'bg-gray-100 text-gray-800'}
    `}>
      {status}
    </span>
  );

  const PriorityBadge = ({ priority }) => (
    <span className={`
      inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
      ${priority === 'High' ? 'bg-red-100 text-red-800' :
        priority === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
        'bg-green-100 text-green-800'}
    `}>
      {priority}
    </span>
  );

  const InfoItem = ({ label, value, fullWidth = false }) => (
    <div className={`bg-gray-50 rounded-lg p-4 ${fullWidth ? 'col-span-2' : ''}`}>
      <label className="block text-sm font-medium text-gray-500 mb-1">{label}</label>
      <div className="text-gray-900">{value}</div>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl w-full max-w-5xl max-h-[90vh] overflow-y-auto shadow-xl">
        {/* Header */}
        <div className="border-b border-gray-200">
          <div className="px-6 py-4">
            <div className="flex justify-between items-start">
              <div>
                <h2 className="text-2xl font-bold text-gray-900 mb-2">{project.Project_Name}</h2>
                <div className="flex items-center space-x-3">
                  <StatusBadge status={project.State} />
                  <PriorityBadge priority={project.Priority} />
                  <span className="text-sm text-gray-500">
                    {new Date(project.Start_Date).toLocaleDateString()} - {new Date(project.End_Date).toLocaleDateString()}
                  </span>
                </div>
              </div>
              <button
                onClick={onClose}
                className="rounded-full p-2 hover:bg-gray-100 transition-colors"
              >
                <svg className="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="p-6 space-y-8">
          {/* Project Overview */}
          <section>
            <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
              <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
              </svg>
              Project Overview
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg p-4">
                <div className="text-sm font-medium text-gray-500 mb-2">Overall Completion</div>
                <div className="mb-2">
                  <ProgressBar progress={project.Completion} />
                </div>
                <div className="text-2xl font-bold text-indigo-600">{project.Completion}%</div>
              </div>
              <InfoItem 
                label="Current Stage" 
                value={project.Current_Stage || 'Not specified'} 
              />
            </div>
          </section>

          {/* Project Team */}
          <section>
            <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
              <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
              Project Team
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InfoItem 
                label="Scrum Master" 
                value={project.Scrum_Master || 'Not assigned'} 
              />
              <InfoItem 
                label="Product Owner" 
                value={project.Product_Owner || 'Not assigned'} 
              />
              <div className="col-span-2 bg-gray-50 rounded-lg p-4">
                <label className="block text-sm font-medium text-gray-500 mb-2">Team Members</label>
                <div className="flex flex-wrap gap-2">
                  {project.Owners && project.Owners.length > 0 ? (
                    project.Owners.map((owner) => (
                      <span
                        key={owner.id}
                        className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800"
                      >
                        {owner.Name}
                      </span>
                    ))
                  ) : (
                    <span className="text-gray-500">No team members assigned</span>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* Project Progress */}
          <section>
            <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
              <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
              Project Progress
            </h3>
            <div className="grid grid-cols-1 gap-4">
              <InfoItem 
                label="Previous Week Progress" 
                value={project.Previous_Week_Progress || 'No progress reported'} 
                fullWidth 
              />
              <InfoItem 
                label="Current State" 
                value={project.Current_State || 'No current state reported'} 
                fullWidth 
              />
              <InfoItem 
                label="Next Actions" 
                value={project.Next_Actions || 'No next actions defined'} 
                fullWidth 
              />
              <InfoItem 
                label="Support Needed" 
                value={project.Support_Needed || 'No support needed'} 
                fullWidth 
              />
            </div>
          </section>

          {/* Related Tasks */}
          <section>
            <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
              <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
              </svg>
              Related Tasks
            </h3>
            {project.Tasks && project.Tasks.length > 0 ? (
              <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Task Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Due Date</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Completion</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {project.Tasks.map((task) => (
                        <tr key={task.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{task.Task_Name}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <StatusBadge status={task.State} />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {new Date(task.Due_Date).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <ProgressBar progress={task.Completion} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="text-center py-8 bg-gray-50 rounded-lg">
                <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
                <p className="mt-2 text-sm text-gray-500">No tasks associated with this project</p>
              </div>
            )}
          </section>

          {/* Project Goal */}
          {project.Goal && (
            <section>
              <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
                Associated Goal
              </h3>
              <div className="bg-gradient-to-br from-purple-50 to-indigo-50 rounded-lg p-6">
                <h4 className="font-medium text-lg text-gray-900 mb-2">{project.Goal.Goal_Name}</h4>
                <div className="mb-2">
                  <ProgressBar progress={project.Goal.Overall_Progress} />
                </div>
                <p className="text-sm text-gray-600 mt-2">Overall Progress: {project.Goal.Overall_Progress}%</p>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectModal; 