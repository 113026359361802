import React, { useState } from 'react';
import FilterableTable from './FilterableTable';
import ProgressBar from './ProgressBar';
import PriorityBadge from './PriorityBadge';
import ProjectModal from './ProjectModal';

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

const StatusTables = ({ goals, projects, tasks }) => {
  const [selectedProject, setSelectedProject] = useState(null);

  const goalColumns = [
    { key: 'Goal_Name', header: 'Goal Name' },
    { key: 'Start_Date', header: 'Start Date', render: formatDate },
    { key: 'End_Date', header: 'End Date', render: formatDate },
    { 
      key: 'Priority',
      header: 'Priority',
      render: (priority) => <PriorityBadge priority={priority} />
    },
    { 
      key: 'State', 
      header: 'Status',
      render: (state) => (
        <span className={`px-2 py-1 rounded-full text-xs font-medium ${
          state === 'Done' ? 'bg-green-100 text-green-800' :
          state === 'In Progress' ? 'bg-blue-100 text-blue-800' :
          'bg-gray-100 text-gray-800'
        }`}>
          {state}
        </span>
      )
    },
    { 
      key: 'Overall_Progress', 
      header: 'Progress',
      render: (value) => <ProgressBar progress={value} />
    },
    {
      key: 'Projects',
      header: 'Projects',
      render: (projects) => projects?.length || 0
    }
  ];

  const projectColumns = [
    { 
      key: 'Project_Name', 
      header: 'Project Name',
      render: (name, project) => (
        <button
          onClick={() => setSelectedProject(project)}
          className="text-left text-indigo-600 hover:text-indigo-900"
        >
          {name}
        </button>
      )
    },
    { key: 'Start_Date', header: 'Start Date', render: formatDate },
    { key: 'End_Date', header: 'End Date', render: formatDate },
    { 
      key: 'Priority',
      header: 'Priority',
      render: (priority) => <PriorityBadge priority={priority} />
    },
    { 
      key: 'State', 
      header: 'Status',
      render: (state) => (
        <span className={`px-2 py-1 rounded-full text-xs font-medium ${
          state === 'Done' ? 'bg-green-100 text-green-800' :
          state === 'In Progress' ? 'bg-blue-100 text-blue-800' :
          'bg-gray-100 text-gray-800'
        }`}>
          {state}
        </span>
      )
    },
    { 
      key: 'Completion', 
      header: 'Progress',
      render: (value) => <ProgressBar progress={value} />
    },
    {
      key: 'Tasks',
      header: 'Tasks',
      render: (tasks) => tasks?.length || 0
    }
  ];

  const taskColumns = [
    { key: 'Task_Name', header: 'Task Name' },
    { 
      key: 'Project',
      header: 'Project',
      render: (project) => project?.Project_Name || '-'
    },
    { key: 'Start_Date', header: 'Start Date', render: formatDate },
    { key: 'Due_Date', header: 'Due Date', render: formatDate },
    { 
      key: 'Priority',
      header: 'Priority',
      render: (priority) => <PriorityBadge priority={priority} />
    },
    { 
      key: 'State', 
      header: 'Status',
      render: (state) => (
        <span className={`px-2 py-1 rounded-full text-xs font-medium ${
          state === 'Done' ? 'bg-green-100 text-green-800' :
          state === 'In Progress' ? 'bg-blue-100 text-blue-800' :
          'bg-gray-100 text-gray-800'
        }`}>
          {state}
        </span>
      )
    },
    { 
      key: 'Completion', 
      header: 'Progress',
      render: (value) => <ProgressBar progress={value} />
    },
    {
      key: 'Assignee',
      header: 'Assignee',
      render: (assignee) => assignee?.Name || '-'
    }
  ];

  return (
    <div className="space-y-8">
      <FilterableTable
        title="Goals Overview"
        data={goals}
        columns={goalColumns}
        filterKey="State"
      />
      <FilterableTable
        title="Projects Overview"
        data={projects}
        columns={projectColumns}
        filterKey="State"
      />
      <FilterableTable
        title="Tasks Overview"
        data={tasks}
        columns={taskColumns}
        filterKey="State"
      />
      {selectedProject && (
        <ProjectModal
          project={selectedProject}
          isOpen={true}
          onClose={() => setSelectedProject(null)}
        />
      )}
    </div>
  );
};

export default StatusTables; 