import React from 'react';

const PriorityBadge = ({ priority }) => {
  const getColorClasses = () => {
    switch (priority) {
      case 'High':
        return 'bg-red-100 text-red-800';
      case 'Medium':
        return 'bg-yellow-100 text-yellow-800';
      case 'Low':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <span className={`px-2 py-1 rounded-full text-xs font-medium ${getColorClasses()}`}>
      {priority || 'Low'}
    </span>
  );
};

export default PriorityBadge;
