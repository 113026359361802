import React from 'react';

const DashboardHeader = ({ dashboardInfo, onLogout }) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  if (!dashboardInfo) return null;

  const getLogoUrl = (logo) => {
    if (!logo) return null;
    const url = logo.url;
    if (!url) return null;
    return url.startsWith('http') ? url : `https://admin.enajz.info${url}`;
  };

  return (
    <header className="bg-white shadow">
      <div className="container mx-auto px-4 py-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold text-gray-900">
              {dashboardInfo?.Title || 'Strategic Goals Dashboard'}
            </h1>
            <p className="mt-1 text-sm text-gray-500">
              {dashboardInfo?.Description || 'Track and monitor organizational goals, projects, and KPIs in real-time.'}
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <div className="text-right">
              <p className="text-sm font-medium text-gray-900">{user.username}</p>
              <p className="text-xs text-gray-500">{user.email}</p>
            </div>
            <button
              onClick={onLogout}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader; 