import React from 'react';
import CollapsibleSection from './CollapsibleSection';

const StatCard = ({ title, value, change }) => {
  const isPositive = parseFloat(change) > 0;
  const changeColor = isPositive ? 'text-green-500' : 'text-red-500';
  const arrow = isPositive ? '↑' : '↓';

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
      <h3 className="text-gray-600 text-sm font-medium mb-3">{title}</h3>
      <div className="flex items-baseline justify-between">
        <p className="text-3xl font-bold text-gray-900">{value}</p>
        <div className="flex items-center">
          <p className={`flex items-baseline text-sm font-semibold ${changeColor}`}>
            <span className="mr-1">{arrow}</span>
            {Math.abs(change)}%
          </p>
        </div>
      </div>
    </div>
  );
};

const DashboardStats = ({ stats }) => {
  if (!stats) return null;

  return (
    <CollapsibleSection title="Dashboard Overview" defaultExpanded={true}>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
        <StatCard
          title="Total Goals"
          value={stats.totalGoals.current}
          change={stats.totalGoals.change}
        />
        <StatCard
          title="Total Projects"
          value={stats.totalProjects.current}
          change={stats.totalProjects.change}
        />
        <StatCard
          title="In Progress Projects"
          value={stats.inProgressProjects.current}
          change={stats.inProgressProjects.change}
        />
        <StatCard
          title="Overall Progress"
          value={`${stats.overallProgress.current}%`}
          change={stats.overallProgress.change}
        />
        <StatCard
          title="Active Tasks"
          value={stats.activeTasks.current}
          change={stats.activeTasks.change}
        />
      </div>
    </CollapsibleSection>
  );
};

export default DashboardStats; 