import React from 'react';
import { Gantt, ViewMode } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import PriorityBadge from './PriorityBadge';

const ProjectTasksGantt = ({ project, tasks }) => {
  const formatDate = (dateString) => {
    if (!dateString) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    }

    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
      }
      date.setHours(0, 0, 0, 0);
      return date;
    } catch (error) {
      console.error('Error formatting date:', error);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    }
  };

  const getGanttTasks = () => {
    const projectTasks = tasks?.filter(task => task.Project?.id === project.id) || [];
    return projectTasks.map(task => ({
      id: `task-${task.id}`,
      name: task.Task_Name,
      start: formatDate(task.Start_Date),
      end: formatDate(task.Due_Date),
      progress: Math.min(Math.max((task.Completion || 0) / 100, 0), 1),
      type: 'task',
      isDisabled: true,
      styles: {
        backgroundColor: task.Priority === 'High' ? '#FEE2E2' : 
                        task.Priority === 'Medium' ? '#FEF3C7' : 
                        '#DCFCE7',
        progressColor: task.Priority === 'High' ? '#DC2626' : 
                      task.Priority === 'Medium' ? '#D97706' : 
                      '#059669',
      },
      displayOrder: task.id,
    }));
  };

  const ganttTasks = getGanttTasks();

  if (ganttTasks.length === 0) {
    return (
      <div className="text-center py-4 text-gray-500 italic">
        No tasks available for this project
      </div>
    );
  }

  return (
    <div className="mt-4">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-sm font-medium text-gray-900">Project Tasks</h4>
      </div>
      <div className="border border-gray-200 rounded-lg overflow-hidden">
        <Gantt
          tasks={ganttTasks}
          viewMode={ViewMode.Day}
          listCellWidth="320px"
          columnWidth={60}
          ganttHeight={ganttTasks.length * 50 + 40}
          barCornerRadius={4}
          barFill={80}
          rowHeight={50}
          handleWidth={8}
          todayColor="rgba(252, 211, 77, 0.25)"
          projectProgressBar={true}
          projectBackgroundColor="#F3F4F6"
          rtl={false}
        />
      </div>
    </div>
  );
};

export default ProjectTasksGantt; 