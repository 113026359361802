import React from 'react';
import './KpiCards.css';
import CollapsibleSection from './CollapsibleSection';

const KpiCards = ({ kpiRecords }) => {
  if (!kpiRecords) return null;

  console.log('KPI Records:', kpiRecords); // Debug log

  const getKpiStatus = (actual, target) => {
    if (!actual || !target) return 'neutral';
    const ratio = (parseFloat(actual) / parseFloat(target)) * 100;
    if (ratio >= 100) return 'success';
    if (ratio >= 80) return 'warning';
    return 'danger';
  };

  const getStatusClasses = (status) => {
    switch (status) {
      case 'success':
        return 'bg-emerald-100 text-emerald-800';
      case 'warning':
        return 'bg-amber-100 text-amber-800';
      case 'danger':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const formatValue = (value, unit) => {
    if (!value) return '0';
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return value;
    return `${numValue.toLocaleString()}${unit ? ` ${unit}` : ''}`;
  };

  const latestKpis = kpiRecords?.reduce((acc, record) => {
    if (!acc[record.KPI_Name] || new Date(record.Date) > new Date(acc[record.KPI_Name].Date)) {
      acc[record.KPI_Name] = record;
    }
    return acc;
  }, {});

  return (
    <CollapsibleSection title="KPIs Overview" defaultExpanded={true}>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
        {Object.values(latestKpis || {}).map((kpi, index) => {
          const status = getKpiStatus(kpi.Actual, kpi.Target);
          return (
            <div 
              key={`${kpi.KPI_Name}-${index}`}
              className="bg-white rounded-lg shadow-sm p-6 transition-transform hover:scale-105"
            >
              <div className="flex justify-between items-start">
                <h3 className="text-lg font-semibold text-gray-900">
                  {kpi.KPI_Name}
                </h3>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusClasses(status)}`}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
              </div>
              <div className="mt-4 flex justify-between items-end">
                <div>
                  <p className="text-sm text-gray-500">Actual</p>
                  <p className="text-2xl font-bold text-gray-900">
                    {formatValue(kpi.Actual, kpi.Unit)}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-gray-500">Target</p>
                  <p className="text-2xl font-bold text-gray-900">
                    {formatValue(kpi.Target, kpi.Unit)}
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <div className="relative pt-1">
                  <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-100">
                    <div
                      className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
                        status === 'success' ? 'bg-emerald-500' :
                        status === 'warning' ? 'bg-amber-500' :
                        'bg-red-500'
                      }`}
                      style={{ 
                        width: `${Math.min(
                          (parseFloat(kpi.Actual) / parseFloat(kpi.Target)) * 100,
                          100
                        )}%` 
                      }}
                    />
                  </div>
                </div>
              </div>
              <p className="mt-2 text-xs text-gray-500 text-right">
                Last updated: {new Date(kpi.Date).toLocaleDateString()}
              </p>
            </div>
          );
        })}
      </div>
    </CollapsibleSection>
  );
};

export default KpiCards; 