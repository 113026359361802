import React, { useState } from 'react';
import CollapsibleSection from './CollapsibleSection';

const FilterableTable = ({ title, data, columns, filterKey }) => {
  const [filter, setFilter] = useState('all');

  if (!data || !columns) return null;

  const filteredData = data.filter(item => {
    if (filter === 'all') return true;
    return item[filterKey]?.toLowerCase() === filter.toLowerCase();
  });

  return (
    <CollapsibleSection title={title}>
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
            <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
            <div className="flex items-center space-x-2">
              {['all', 'to do', 'in progress', 'done'].map((status) => (
                <button
                  key={status}
                  onClick={() => setFilter(status)}
                  className={`
                    px-3 py-2 text-sm font-medium rounded-md
                    ${filter === status
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
                    }
                    transition-colors duration-150
                  `}
                >
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredData.map((item) => (
                <tr key={item.id} className="hover:bg-gray-50">
                  {columns.map((column) => (
                    <td key={column.key} className="px-6 py-4 whitespace-nowrap">
                      {column.render 
                        ? column.render(item[column.key], item)
                        : (
                          <div className="text-sm text-gray-900">
                            {item[column.key]}
                          </div>
                        )
                      }
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {filteredData.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-500 text-sm italic">
                No {filter === 'all' ? '' : filter} {title.toLowerCase()} found
              </p>
            </div>
          )}
        </div>
      </div>
    </CollapsibleSection>
  );
}

export default FilterableTable; 