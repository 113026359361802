import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import config from './config/config';
import KpiCards from './components/KpiCards';
import GoalsTable from './components/GoalsTable';
import ProjectsChart from './components/ProjectsChart';
import TasksTable from './components/TasksTable';
import KpiTrendsChart from './components/KpiTrendsChart';
import GoalsGantt from './components/GoalsGantt';
import ErrorBoundary from './components/ErrorBoundary';
import TeamMembersTable from './components/TeamMembersTable';
import DashboardHeader from './components/DashboardHeader';
import DashboardStats from './components/DashboardStats';
import StatusTables from './components/StatusTables';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    goals: [],
    projects: [],
    tasks: [],
    kpiRecords: [],
    teamMembers: [],
    dashboardInfo: null
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetch(`${config.API_URL}/api/dashboard`)
      .then(res => res.json())
      .then(response => {
        const { data } = response;
        // Transform the data to match the schema
        const transformedData = {
          dashboardInfo: {
            title: data.Title,
            description: data.Description,
            logo: data.Logo
          },
          stats: data.stats,
          goals: data.goals.map(goal => ({
            id: goal.id,
            Goal_Name: goal.Goal_Name,
            Start_Date: goal.Start_Date,
            End_Date: goal.End_Date,
            State: goal.State,
            Overall_Progress: goal.Overall_Progress,
            Projects: goal.Projects?.map(project => ({
              id: project.id,
              Project_Name: project.Project_Name,
              Start_Date: project.Start_Date,
              End_Date: project.End_Date,
              State: project.State,
              Completion: project.Completion,
              Tasks: project.Tasks?.map(task => ({
                id: task.id,
                Task_Name: task.Task_Name,
                Start_Date: task.Start_Date,
                Due_Date: task.Due_Date,
                State: task.State,
                Completion: task.Completion,
                Assignee: task.Assignee
              })) || []
            })) || []
          })),
          projects: data.projects.map(project => ({
            id: project.id,
            Project_Name: project.Project_Name,
            Start_Date: project.Start_Date,
            End_Date: project.End_Date,
            State: project.State,
            Completion: project.Completion,
            Goal: project.Goal,
            Tasks: project.Tasks || [],
            Owners: project.Owners || []
          })),
          tasks: data.tasks.map(task => ({
            id: task.id,
            Task_Name: task.Task_Name,
            Start_Date: task.Start_Date,
            Due_Date: task.Due_Date,
            State: task.State,
            Completion: task.Completion,
            Project: task.Project,
            Assignee: task.Assignee
          })),
          kpiRecords: data.kpiRecords.map(record => ({
            id: record.id,
            KPI_ID: record.id,
            KPI_Name: record.KPI_Name,
            Target: record.Target || '0',
            Actual: record.Actual || '0',
            Unit: record.Unit || '',
            Date: record.Date,
            State: record.State
          })),
          teamMembers: data.teamMembers?.map(member => ({
            id: member.id,
            Member_ID: member.id,
            Name: member.Name,
            Email: member.Email,
            Role: member.Role,
            Tasks: member.Tasks || []
          })) || []
        };
        setDashboardData(transformedData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Switch>
        <Route 
          path="/login" 
          render={() => 
            isAuthenticated ? (
              <Redirect to="/dashboard" />
            ) : (
              <Login setIsAuthenticated={setIsAuthenticated} />
            )
          }
        />
        <Route 
          path="/dashboard" 
          render={() => 
            isAuthenticated ? (
              <Dashboard 
                setIsAuthenticated={setIsAuthenticated}
                dashboardData={dashboardData}
              />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route 
          exact 
          path="/" 
          render={() => <Redirect to={isAuthenticated ? "/dashboard" : "/login"} />}
        />
      </Switch>
    </Router>
  );
};

export default App; 