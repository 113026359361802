import React, { useState, useEffect } from 'react';
import ProjectTasksGantt from './ProjectTasksGantt';
import StatusBadge from './StatusBadge';
import PriorityBadge from './PriorityBadge';
import CollapsibleSection from './CollapsibleSection';

const GoalsTable = ({ goals, projects, tasks }) => {
  const [expandedGoals, setExpandedGoals] = useState(new Set());
  const [expandedProjects, setExpandedProjects] = useState(new Set());

  // Initialize with all goals and projects collapsed
  useEffect(() => {
    setExpandedGoals(new Set());
    setExpandedProjects(new Set());
  }, [goals, projects]);

  if (!goals) return null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const toggleGoal = (goalId) => {
    const newExpanded = new Set(expandedGoals);
    if (newExpanded.has(goalId)) {
      newExpanded.delete(goalId);
      // Close all projects under this goal
      const goal = goals.find(g => g.id === goalId);
      goal?.Projects?.forEach(project => {
        setExpandedProjects(prev => {
          const newSet = new Set(prev);
          newSet.delete(project.id);
          return newSet;
        });
      });
    } else {
      newExpanded.add(goalId);
    }
    setExpandedGoals(newExpanded);
  };

  const toggleProject = (projectId, event) => {
    event.stopPropagation();
    setExpandedProjects(prev => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(projectId)) {
        newExpanded.delete(projectId);
      } else {
        newExpanded.add(projectId);
      }
      return newExpanded;
    });
  };

  const calculateGoalProgress = (goal) => {
    if (!goal.Projects || goal.Projects.length === 0) {
      return goal.Overall_Progress || 0;
    }
    const totalProgress = goal.Projects.reduce((sum, project) => sum + (project.Completion || 0), 0);
    return Math.round(totalProgress / goal.Projects.length);
  };

  return (
    <CollapsibleSection title="Goals Gantt Chart" defaultExpanded={true}>
      <div className="relative">
        <div className="max-h-[800px] overflow-y-auto">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 sticky top-0 z-10">
                  <tr>
                    <th scope="col" className="w-10 px-6 py-3"></th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider min-w-[200px]">
                      Goal Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider min-w-[120px]">
                      Start Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider min-w-[120px]">
                      End Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider min-w-[100px]">
                      Priority
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider min-w-[100px]">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider min-w-[150px]">
                      Progress
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {goals.map((goal) => (
                    <React.Fragment key={`goal-group-${goal.id}`}>
                      <tr 
                        className="hover:bg-gray-50 cursor-pointer"
                        onClick={() => toggleGoal(goal.id)}
                      >
                        <td className="px-6 py-4">
                          <button className="text-gray-400 hover:text-gray-600">
                            {expandedGoals.has(goal.id) ? '−' : '+'}
                          </button>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {goal.Goal_Name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {formatDate(goal.Start_Date)}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {formatDate(goal.End_Date)}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <PriorityBadge priority={goal.Priority} />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <StatusBadge status={goal.State} />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="w-full bg-gray-200 rounded-full h-2.5">
                            <div
                              className="bg-indigo-600 h-2.5 rounded-full"
                              style={{ width: `${calculateGoalProgress(goal)}%` }}
                            ></div>
                          </div>
                          <span className="text-xs text-gray-500 mt-1">
                            {calculateGoalProgress(goal)}%
                          </span>
                        </td>
                      </tr>
                      {expandedGoals.has(goal.id) && goal.Projects && goal.Projects.length > 0 && (
                        <>
                          <tr className="bg-gray-50">
                            <td colSpan="7" className="px-6 py-3">
                              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Projects
                              </div>
                            </td>
                          </tr>
                          {goal.Projects.map(project => (
                            <React.Fragment key={`project-group-${project.id}`}>
                              <tr className="bg-gray-50/50 hover:bg-gray-100">
                                <td className="px-6 py-4">
                                  <button 
                                    className="text-gray-400 hover:text-gray-600 ml-4"
                                    onClick={(e) => toggleProject(project.id, e)}
                                  >
                                    {expandedProjects.has(project.id) ? '−' : '+'}
                                  </button>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 flex items-center">
                                    <span className="text-gray-400 mr-2">└</span>
                                    {project.Project_Name}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900">
                                    {formatDate(project.Start_Date)}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900">
                                    {formatDate(project.End_Date)}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <PriorityBadge priority={project.Priority} />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <StatusBadge status={project.State} />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                                    <div
                                      className="bg-emerald-500 h-2.5 rounded-full"
                                      style={{ width: `${project.Completion || 0}%` }}
                                    ></div>
                                  </div>
                                  <span className="text-xs text-gray-500 mt-1">
                                    {project.Completion || 0}%
                                  </span>
                                </td>
                              </tr>
                              {expandedProjects.has(project.id) && (
                                <tr>
                                  <td colSpan="7" className="px-6 py-4 bg-gray-50/30">
                                    <div className="overflow-x-auto">
                                      <ProjectTasksGantt 
                                        project={project}
                                        tasks={tasks}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </CollapsibleSection>
  );
};

export default GoalsTable; 